<template>
  <div>
    <div class="card">
      <div class="overflow-auto">
        <b-table
          id="summaryTable"
          :items="items"
          :fields="fields"
          class="mb-0"
          striped
          empty-text="No Records Found"
          responsive
        >
          <template #head()="data">
            <span class="column-heading">{{ data.label }}</span>
          </template>
          <template #cell()="data">
            <span class="column-data">{{ data.value }}</span>
          </template>
        </b-table>
      </div>
    </div>
    <w-pagination
      :currentPage="currentPage"
      :perPage="perPage"
      :totalRows="totalRows"
      @getSelectedPageEvent="$emit('getSelectedPageEvent',$event)"
    >
    </w-pagination>
  </div>
</template>

<script>
import Pagination from "@/widgets/Pagination.vue";
export default {
  name: "SummaryTable",
  components: {
    "w-pagination": Pagination,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    currentPage: {
      type: Number,
      default: 1,
    },
    perPage: {
      type: Number,
      default: 5,
    },
    totalRows: {
      type: Number,
      default: 0,
    },
    extraHeader: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      fields: [],
    };
  },
  watch: {
    extraHeader(newVal) {
      this.fields = [];
      newVal.forEach((element) => {
        const obj = {
          key: element.key,
          label: element.label,
          sortable: false,
          tdClass: "text-center",
          thClass: "text-center",
        };
        this.fields.push(obj);
      });
    },
  },
};
</script>
<style scoped>
.column-heading {
  font-family: ProximaNovaBold;
  font-size: 16px;
  color: #222a37;
  margin: 40px 0;
}
.column-data {
  font-family: ProximaNovaRegular;
  font-size: 16px;
  color: #222a37;
  font-weight: normal;
}
>>> .table > tbody > tr {
  outline: none;
}
>>> .table > tbody > tr > td {
  padding: 24px;
}
>>> .table > thead > tr {
  box-shadow: 0 0px 8px 0 #c9ced5;
}
>>> .table > thead > tr > th {
  outline: none;
  padding: 24px 24px;
  line-height: 1;
}
>>> .table > :not(:last-child) > :last-child > * {
  border: none;
}

>>> .table-striped > tbody > tr:nth-of-type(odd) {
  --bs-table-accent-bg: transparent;
  background: none;
}
>>> .table-striped > tbody > tr:nth-of-type(even) {
  background-color: #f2f5fa;
}
.card {
  border: none;
}

>>> .page-item.disabled .page-link {
  background-color: transparent;
}
>>> .page-link {
  height: 36px;
  width: 36px;
  background-color: transparent;
  border: none;
  font-size: 16px;
  font-family: ProximaNovaRegular;
  color: #9aafd4;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
}

>>> .page-link i {
  font-style: normal;
}

>>> .active > .page-link {
  background-color: transparent;
  border: 2px solid #050505;
  font-size: 16px;
  font-family: ProximaNovaRegu lar;
  color: #222a37;
  box-shadow: none;
}
</style>
