<template>
  <div class="Cps card">
    <div class="card-heading m-4">{{ title }}</div>
    <div class="d-flex justify-content-center slider-wrapper">
      <div
        class="slider-btn slider-left"
        @click="scrollLeft(2)"
        v-if="cpaData.length > 2"
      >
        <i class="fas fa-chevron-left"></i>
      </div>
      <div ref="slider-2" class="d-flex statistics-wrapper">
        <div v-if="cpaData.length !== 0" class="d-flex">
          <div
            v-for="(data, index) in cpaData"
            :key="index"
            class="small-card d-flex"
            @click="boxClicked(data)"
          >
            <div
              class="small-card-icon-wrapper d-flex align-items-center justify-content-center"
              :style="{ background: getColor(index) }"
            >
              <!-- <img class="small-card-icon" :src="getImage(data.title).url" /> -->
            </div>
            <div>
              <div class="small-card-heading">{{ data.title }}</div>
              <div class="small-card-value">{{ data.value }}</div>
            </div>
          </div>
        </div>
        <div class="text-center font-medium w-100 ProximaNovaSemiBold" v-else>
          <div class="row w-100">
            <div v-html="noDataText"></div>
            <div
              class="content-center ProximaNovaSemiBold"
              v-if="status === 'error'"
            >
              <div @click="$emit('retry')" class="content-center refreshButton">
                <span class="pr-3 font-medium">Retry</span
                ><span class="refreshIcon"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="slider-btn slider-right"
        @click="scrollRight(2)"
        v-if="cpaData.length > 2"
      >
        <i class="fas fa-chevron-right"></i>
      </div>
    </div>
  </div>
</template>

<script>
import "@/mixins/generalMixin.js";
export default {
  props: {
    noDataText: {
      type: String,
      default: "",
    },
    cpaData: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: "",
    },
    status: {
      type: String,
      default: "initial",
    },
  },
  data() {
    return {};
  },
  methods: {
    getColor(ind) {
      var first = this.ColorLuminance(
        window.theme.colors[ind] || window.theme.colors[9],
        0.2
      );
      var sec = this.ColorLuminance(
        window.theme.colors[ind] || window.theme.colors[9],
        -0.5
      );
      var result = "linear-gradient(135deg," + first + " 0%, " + sec + " 100%)";
      return result;
    },
    // getImage(image) {
    //   try {
    //     return {
    //       url: require("@/assets/connectors/" + image + ".svg"),
    //       showtext: true,
    //     };
    //   } catch (error) {
    //     if (error.code === "MODULE_NOT_FOUND") {
    //       try {
    //         return {
    //           url: require("@/assets/connectors/" + image + ".jpg"),
    //           showtext: true,
    //         };
    //       } catch (error) {
    //         if (error.code === "MODULE_NOT_FOUND") {
    //           try {
    //             return {
    //               url: require("@/assets/connectors/" + image + ".png"),
    //               showtext: true,
    //             };
    //           } catch {
    //             return { url: image, showtext: true };
    //           }
    //         }
    //         return { url: image, showtext: true };
    //       }
    //     }
    //     return { url: image, showtext: true };
    //   }
    // },
    scrollRight(index) {
      this.$refs[`slider-${index}`].scrollLeft += 50;
    },
    scrollLeft(index) {
      this.$refs[`slider-${index}`].scrollLeft -= 50;
    },
    boxClicked(data) {
      this.$emit("channelClicked", data);
    },
  },
};
</script>

<style scoped>
.card-heading {
  font-family: ProximaNovaBold;
  font-size: 20px;
  color: #222a37;
}
.card {
  box-shadow: 0 0px 8px 0 #c9ced5;
  background-color: #fff;
}
.slider-wrapper {
  margin-bottom: 17px;
}
.slider-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 31px;
  z-index: 9;
  margin: 8px 0px;
  cursor: pointer;
  font-size: 20px;
}

.slider-left {
  box-shadow: 10px 4px 29px #fff;
}
.slider-right {
  box-shadow: -10px 4px 29px #fff;
}
.statistics-wrapper {
  padding: 24px 8px;
  overflow-x: hidden;
  width: 100%;
}
.small-card {
  padding: 16px 0;
  margin-right: 16px;
  width: max-content;
  height: 80px;
  min-width: 200px;
  box-shadow: 0 0 8px 0 #c9ced5;
  cursor: pointer;
}

.small-card:hover,
.small-card:hover .small-card-heading {
  background-color: #050505;
  color: #fff;
}

.small-card:hover .small-card-value {
  color: #fff;
}

.small-card-icon-wrapper {
  height: 48px;
  width: 48px;
  margin: 0 16px;
  border-radius: 3px;
}

.small-card-icon {
  height: 24px;
  width: 24px;
}

.small-card-heading {
  font-family: ProximaNovaSemiBold;
  font-size: 16px;
  color: #222a37;
}

.small-card-value {
  font-family: ProximaNovaBold;
  color: #222a37;
  font-size: 16px;
}
</style>
