<template>
  <div>
    <multiselect
      ref="multiselect"
      v-model="value"
      :options="options"
      :multiple="true"
      :close-on-select="false"
      :clear-on-select="false"
      :preserve-search="true"
      :placeholder="placeholder"
      label="name"
      track-by="name"
      :preselect-first="false"
      :show-labels="false"
      :openDirection="'below'"
      class="tableDropdown"
      @input="change($event)"
    >
      <!-- @close="close($event)" -->
      <template slot="selection" slot-scope="{ values }"
        ><span class="multiselect__single" v-if="showPlaceHoaderOnly">{{
          placeholder
        }}</span>
        <span class="multiselect__single" v-else
          >{{ values.length }} selected</span
        >
      </template>
      <template slot="option" slot-scope="props">
        <div class="option__desc" :for="props.option.name + props.option.value">
          <input
            class="form-check-input"
            type="checkbox"
            :id="props.option.name + props.option.value"
            :disabled="props.option.$isDisabled"
            :checked="props.option.value"
          />
          <!-- value.find((o) => o.name === props.option.name) !== undefined -->
          <span class="option__title ml-4">{{ props.option.name }}</span>
        </div>
      </template>
    </multiselect>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
export default {
  props: ["maximum", "options", "placeholder", "showPlaceHoaderOnly"],
  components: {
    Multiselect,
  },
  data() {
    return {
      selectedOption: [],
      value: [],
    };
  },
  methods: {
    change(event) {
      this.options.forEach((element) => {
        event.forEach((el) => {
          if (element.name === el.name) {
            element.value = true;
          }
        });
        if (!event.some((el) => el.name === element.name)) {
          element.value = false;
        }
      });

      if (event.length === this.maximum) {
        this.options.forEach((element) => {
          if (!event.some((el) => el.name === element.name)) {
            element.$isDisabled = true;
          }
        });
      } else {
        this.options.forEach((element) => {
          element.$isDisabled = false;
        });
      }
      this.$emit(
        "getSelectedHeader",
        event.map((el) => ({ label: el.name, key: el.key }))
      );
    },
  },
  created() {
    this.value = this.options.filter((el) => el.value === true);
    this.$emit(
      "getSelectedHeader",
      this.value.map((el) => ({ label: el.name, key: el.key }))
    );
  },
};
</script>
<style>
.tableDropdown.multiselect {
  min-height: 55px;
  min-width: 230px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 0 8px 0 #c9ced5;
  border-radius: 3px;
  background: #fff;
  z-index: 9;
}
.multiselect__single {
  width: auto;
  margin: 0;
  font-size: 16px;
  /* background: #; */
  color: #050505;
}
.multiselect__select {
  width: 36px;
  height: 100%;
}
.tableDropdown .multiselect__tags {
  min-height: 0px;
  font-family: ProximaNovaRegular;
  font-weight: 600;
  padding: 0px;
  border: none;
}
.multiselect__select:before {
  border-color: #050505 transparent transparent;
}
.multiselect__content-wrapper {
  background-color: #fff;
 box-shadow: 0px 5px 5px 0px #c9ced5;
  top: 65px;
}

.multiselect__option--selected,
.multiselect__option--group {
  color: #050505;
  background: #fff !important;
}
.multiselect__option {
  background: #fff !important;
  color: #050505;
  font-family: ProximaNovaRegular;
  font-size: 16px;
}

.multiselect__input {
  background: transparent;
  width: 0% !important;
}
.multiselect__placeholder {
  padding-left: 5px;
  padding-top: 0px;
  margin: 0px;
  font-size: 13px;
  color: #050505;
}
.option__desc {
  display: flex;
  align-items: center;
}
.checkbox-option {
  height: 24px;
  margin: 0px;
  margin-right: 20px;
  width: 24px;
}

.form-check-input {
  height: 24px;
  position: relative;
  top: 0px;
  margin: 0px;
  margin-right: 10px;
  border-color: #222a37;
}

.form-check-input:checked {
  background-color: #222a37;
  border-color: #222a37;
}
.multiselect__option--selected.multiselect__option--highlight {
  color: #050505;
}
.multiselect__option--disabled {
  color: #989898;
}
label {
  cursor: pointer;
}
</style>
