import Vue from "vue";
Vue.mixin({
  methods: {
    changeCase(data) {
      if(data.length === 3){
        return data.toUpperCase()
      }
      var result = data.replace(/([A-Z])/g, " $1");
      var finalResult = result.charAt(0).toUpperCase() + result.slice(1);
      return finalResult;
    },
  },
});
