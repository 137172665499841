import Vue from "vue";
Vue.mixin({
  methods: {
    ColorLuminance(hex, lum) {
      // validate hex string
      hex = String(hex).replace(/[^0-9a-f]/gi, "");
      if (hex.length < 6) {
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
      }
      lum = lum || 0;

      // convert to decimal and change luminosity
      var rgb = "#",
        c,
        i;
      for (i = 0; i < 3; i++) {
        c = parseInt(hex.substr(i * 2, 2), 16);
        c = Math.round(Math.min(Math.max(0, c + c * lum), 255)).toString(16);
        rgb += ("00" + c).substr(c.length);
      }

      return rgb;
    },
  },
});
window.theme = {
  colors: [
    "#d92e72",
    "#ff9d4a",
    "#33ffcc",
    "#a62dc6",
    "#9ec707",
    "#feb92e",
    "#df779a",
    "#ea8f63",
    "#70b8d0",
    "#86b476",
    "#de6761",
    "#85edff",
    "#b5a9ff",
    "#DDDF00",
    "#24CBE5",
    "#64E572",
    "#FF9655",
    "#FFF263",
    "#6AF9C4",
  ],
};
